<template>
  <div class="row">
    <div class="col-md-5">
      <div
    v-if="isLoading"
    style="
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div class="text-center px-5 pb-7">
      <img
        alt="Logo"
        class="max-h-50px"
        src="/media/loading_icon.gif"
        style="width: 40px"
      />
    </div>
  </div>
      <div v-else class="card mb-5 mb-xl-10 bg-light-warning">

    <!--begin::Card body-->
    <div class="card-body p-9 pb-0">
      <!--begin::Row-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">{{ $t("modals.interview.interviewTitle") }}</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bolder fs-6 text-dark">{{poll.surveyTitle}}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">{{ $t("modals.interview.description") }}</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8 fv-row">
          <span class="fw-bold fs-6">{{poll.surveyDescription}}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">
          {{ $t("modals.pollster.startDate") }}
        </label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8 d-flex align-items-center">
          <span class="fw-bolder fs-6 me-2">{{new Date(poll.startDate).toLocaleString('tr-TR', { dateStyle: 'short', timeStyle: 'full' })}}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">{{ $t("modals.pollster.endDate") }}</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
          <a href="#" class="fw-bold fs-6 text-dark text-hover-primary"
            >{{new Date(poll.endDate).toLocaleString('tr-TR', { dateStyle: 'short', timeStyle: 'full' })}}</a
          >
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">
          {{ $t("modals.pollster.duration") }}
        </label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bolder fs-6 text-dark">{{poll.sessionDuration}}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">{{ $t("modals.pollster.status") }}</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bolder fs-6 text-dark">{{poll.status}}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="row mb-10">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">{{ $t("modals.pollster.percentComplete") }}</label>
        <!--begin::Label-->

        <!--begin::Label-->
        <div class="col-lg-8">
          <span class="fw-bold fs-6">{{poll.sessionCompletedPercentage}}</span>
        </div>
        <!--begin::Label-->
      </div>
      <!--end::Input group-->
    </div>
    <!--end::Card body-->
  </div>
  <div class="card mb-5 mb-xl-10">
    <div class="card bg-light-success" style="margin-bottom: 0px">
    <!--begin::Body-->
    <div class="card-body my-3">
      <a
        href="#"
        :class="`text-success`"
        class="card-title fw-bolder fs-5 mb-3 d-block"
      >
       Performans
      </a>

      <div class="py-1">
        <span class="text-dark fs-1 fw-bolder me-2">90%</span>

        <span class="fw-bold text-muted fs-7">CV ve Mülakat'ın iş tanımına uyumluluk durumu</span>
      </div>

      <div :class="`bg-success`" class="progress h-7px bg-opacity-50 mt-7">
        <div
          :class="`bg-success`"
          class="progress-bar"
          role="progressbar"
          :style="`width: success`"
          aria-valuenow="50"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
    <!--end:: Body-->
  </div>
  </div>
</div>
<div class="col-md-7"> 
  <div class="card mb-5 mb-xl-10" >
    <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder fs-3 mb-1">{{ $t("modals.interview.userInterviewDetail.tabs.three") }}</span>
            </h3>
          </div>
    <div class="card-body p-9 pt-0 mb-5" style="height:550px; overflow: auto;"> 
    <div
    class="row mt-5"
    v-for="(log, index) in pollChatLogs"
    :key="index"
  >
    <MessageIn
      v-if="log.poll.owner == 'AI'"
      ref="messagesInRef"
      :text="log.poll.text"
      :id="log.poll.id"
      :voice="log.poll.voice"
    ></MessageIn>
    <MessageOut v-else ref="messagesOutRef" :text="log.poll.text"
    :voice="log.poll.voice"
    :video="log.poll.video"
    :id="log.poll.id"></MessageOut>
    
  </div>
</div>
</div>
</div>
</div>
  <!--end::Form-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import MessageIn from "@/presentation/components/chat-log/MessageIn.vue";
import MessageOut from "@/presentation/components/chat-log/MessageOut.vue";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute, useRouter } from "vue-router";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { PollSessionDetailModel } from "@/domain/pollster/poll-statistic/poll-session-detail/model/PollSessionDetailModel";
import { PollMessageListModel } from "@/domain/pollster/poll-statistic/poll-message-list/model/PollMessageListModel";

export default defineComponent({
  name: "userPollDetail",
  components: {
    Field,
    Form,
    ErrorMessage,
    MessageIn,
    MessageOut,
  },
  computed: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref<boolean>(false);

    const pollId = ref<any>(Number(route.params["id"]));

    const store = useStore();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const swalNotification = new SwalNotification();

    const poll = ref<PollSessionDetailModel>({});
      const pollChatLogs = ref<PollMessageListModel[]>([]);

    const getPoll = async () => {
      isLoading.value = true;

      const pollSessionDetailModel: PollSessionDetailModel = {
        id: pollId.value,
      };

      pollsterController
        .pollSessionDetail(pollSessionDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(poll.value, response.getValue());
            if (poll.value.endDate) {
              poll.value.status = false; // status true ise açıktır. false ise kapanmıstır/bitmistir
            } else {
              poll.value.status = true;
            }
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getPollChatLogs = async () => {
      const pollMessageListModel: PollMessageListModel = {
        poll: {
          id: pollId.value,
        },
      };

      pollsterController
        .pollSessionMessages(pollMessageListModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(pollChatLogs.value, response.getValue());
          } else {
            if (pollChatLogs.value.length == 0) {
              router.push({ name: "500" });
            }
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };


    const questionTypeLabel = (value?: string): string => {
      switch (value?.toString()) {
        case "OPEN_ENDED":
          return "Açık Uçlu";
        case "SINGLE_CHOICE":
          return "Tek Seçenekli";
        case "MULTIPLE_CHOICE":
          return "Çok Seçenekli";
        default:
          return "";
      }
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getPollChatLogs();
      getPoll();
    });

    return {
      questionTypeLabel,
      poll,
      isLoading,
      pollChatLogs,
    };
  },
});
</script>
